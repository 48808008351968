import { Injectable } from '@angular/core';
import { OrderItem } from '../../models/order-item';
import { OfflineProduct } from '../../models/offline-product';
import { ICreateReplacementItem } from '../../dtos/i-create-replacement-item';
import { ICreateOrderItem } from '../../dtos/i-create-order-item';
import { prepareOrderItem } from '../../helpers/orderitems.helper';
import { OrderItemDepositProduct } from '../../models/order-item-deposit-product';

@Injectable({
    providedIn: 'root',
})
export class OfflineProductMapperService {
    constructor() {}

    public mapOfflineProductToOrderItem(
        offlineProduct: OfflineProduct,
        orderItemID: number,
        quantity: number
    ): OrderItem {
        const orderItem = new OrderItem();
        orderItem.orderReplacementItem = null;
        orderItem.orderItemID = orderItemID;
        orderItem.orderItemName = offlineProduct.productName;
        orderItem.quantity = quantity ?? offlineProduct.quantity;
        orderItem.collectedQuantity = 0;
        orderItem.unitPrice = offlineProduct.markupPrice;
        orderItem.sku = offlineProduct.sku;
        orderItem.gtinList = offlineProduct.gtinList;
        orderItem.vat = offlineProduct.vat;
        orderItem.unit = offlineProduct.unit;
        orderItem.productPhotoID = offlineProduct.productPhotoID;
        orderItem.providerProductImageURL = offlineProduct.providerProductImageURL;
        orderItem.categoryNameToDisplayTranslationCode =
            offlineProduct.categoryNameToDisplayTranslationCode;
        orderItem.providerOfflineProductID = offlineProduct.providerOfflineProductID;
        orderItem.isBulk = offlineProduct.isBulk;
        orderItem.isWerwProduct = offlineProduct.isWerwProduct;
        orderItem.productProviderID = offlineProduct.productProviderID;
        orderItem.orderItemDepositProductList = [];
        orderItem.optionalDepositProductQuantity = 0;
        orderItem.orderReplacementItemDepositProductList = [];
        orderItem.shelfPositionDisplayNameKey =
            offlineProduct.shelfPositionDisplayNameKey;
        orderItem.productLocationMapImageUrl = offlineProduct.productLocationMapImageUrl;
        orderItem.shelfDisplayNameKey = offlineProduct.shelfDisplayNameKey;
        orderItem.sequenceOrder = offlineProduct.sequenceOrder;

        if (
            offlineProduct.providerDepositProductList &&
            offlineProduct.providerDepositProductList.length > 0
        ) {
            offlineProduct.providerDepositProductList?.forEach(
                (orderItemDepositProduct) => {
                    orderItem.orderItemDepositProductList.push({
                        collectedQuantity: 0,
                        depositUnitTypeIsOptional:
                            orderItemDepositProduct.depositUnitType.isOptional,
                        depositUnitTypeName:
                            orderItemDepositProduct.depositUnitType.depositUnitTypeName,
                        description: '',
                        maxQuantity: orderItemDepositProduct.maxQuantity,
                        name: orderItemDepositProduct.name,
                        unitName:
                            orderItemDepositProduct.depositUnitType.depositUnitTypeName,
                        price: orderItemDepositProduct.price,
                        unitValue: orderItemDepositProduct.maxQuantity,
                        maxQuantityByUnitValue: orderItemDepositProduct.maxQuantity,
                        netPrice: orderItemDepositProduct.netPrice,
                        orderItemID: 0,
                        orderItemDepositProductID: '',
                    } as OrderItemDepositProduct);
                }
            );
        }
        return prepareOrderItem(orderItem);
    }

    public mapOfflineProductToCreateOrderItem(
        orderID: number,
        offlineProduct: OfflineProduct,
        isCollecting = true
    ): ICreateOrderItem {
        const createOrderItem: ICreateOrderItem = {
            name: offlineProduct.productName,
            orderID,
            quantity: offlineProduct.quantity,
            isCollecting,
            unit: offlineProduct.unit,
            unitPrice: offlineProduct.markupPrice,
            sku: offlineProduct.sku,
            vat: offlineProduct.vat,
            orderItemStateID: null,
            offlineProductID: offlineProduct.providerOfflineProductID,
            isWerwProduct: offlineProduct.isWerwProduct,
            isBulk: offlineProduct.isBulk,
            productProviderID: offlineProduct.productProviderID,
            orderItemDepositProductList: [],
        };

        offlineProduct.providerDepositProductList?.forEach((orderItemDepositProduct) => {
            createOrderItem.orderItemDepositProductList.push({
                collectedQuantity: 0,
                depositUnitTypeIsOptional:
                    orderItemDepositProduct.depositUnitType.isOptional,
                depositUnitTypeName:
                    orderItemDepositProduct.depositUnitType.depositUnitTypeName,
                description: '',
                maxQuantity: orderItemDepositProduct.maxQuantity,
                name: orderItemDepositProduct.name,
                unitName: orderItemDepositProduct.depositUnitType.depositUnitTypeName,
                price: orderItemDepositProduct.price,
                unitValue: orderItemDepositProduct.maxQuantity,
                maxQuantityByUnitValue: orderItemDepositProduct.maxQuantity,
                netPrice: orderItemDepositProduct.netPrice,
                orderItemID: 0,
                orderItemDepositProductID: this.newGuid(),
            } as OrderItemDepositProduct);
        });

        return createOrderItem;
    }

    public mapOfflineProductToCreateReplacementItem(
        orderItemID: number,
        offlineProduct: OfflineProduct,
        isCollecting = true
    ): ICreateReplacementItem {
        const createReplacementItem: ICreateReplacementItem = {
            orderItemID,
            replacementItemName: offlineProduct.productName,
            replacementItemQuantity: offlineProduct.quantity,
            replacementItemUnitPrice: offlineProduct.markupPrice,
            unit: offlineProduct.unit,
            vat: offlineProduct.vat,
            sku: offlineProduct.sku,
            offlineProductID: offlineProduct.providerOfflineProductID,
            isWerwProduct: offlineProduct.isWerwProduct,
            isBulk: offlineProduct.isBulk,
            isCollecting: isCollecting,
            optionalDepositProductQuantity: 0,
            orderItemDepositProductList: null,
            orderReplacementItemDepositProductList: null,
        };

        return createReplacementItem;
    }

    newGuid() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (Math.random() * 16) | 0,
                v = c == 'x' ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        });
    }
}
