<button
    *ngIf="task.state === DeliveryTaskState[DeliveryTaskState.WaitingForAccept]"
    class="btn btn-block btn-primary"
    (click)="acceptDelivering()"
>
    {{ 'my-tasks.accept-task' | translate }}
</button>
<button
    *ngIf="task.state === DeliveryTaskState[DeliveryTaskState.ToDo]"
    class="btn btn-block btn-primary"
    [ngClass]="{
        'hs-cta-trigger-button hs-cta-trigger-button-173107611190': countryCode === 'at',
    }"
    (click)="startDelivering()"
>
    {{ 'my-tasks.start-delivering' | translate }}
</button>
<div
    class="btn-group btn-block"
    *ngIf="task.state === DeliveryTaskState[DeliveryTaskState.InProgress] && getIsPaid()"
>
    <button
        (click)="setDeliveringDoneWithLatLong()"
        class="btn btn-success"
        [ngClass]="{
        'hs-cta-trigger-button hs-cta-trigger-button-173107611190': countryCode === 'at',
    }"
    >
        {{ 'common.done' | translate }}
    </button>
    <button (click)="setDeliveringFailed()" class="btn btn-danger">
        {{ 'common.failed' | translate }}
    </button>
</div>

<div class="btn-group btn-block" *ngIf="showPaymentButton()">
    <button
        (click)="handleSumUpPay()"
        class="btn btn-primary"
        [ngClass]="{ 'btn-info': isButtonBlocked }"
    >
        {{ 'common.payment' | translate }}
    </button>
</div>

<div class="btn-group btn-block" *ngIf="showCashOnDeliveryButton()">
    <button
        (click)="handleCashOnDeliveryPay()"
        class="btn btn-primary"
        [ngClass]="{ 'btn-info': isButtonBlocked }"
    >
        {{ 'common.cash-on-delivery-payment' | translate }}
    </button>
</div>

<small class="mt-2"
    >{{ detroyedComponent }} <br />
    {{ paymentStatus }}</small
>
