import { IGeolocationService } from './igeolocation.service';
import { Observable } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { CheckProviderShop } from '../../dtos/check-provider-shop';
import { Inject, Injectable } from '@angular/core';
import { API_CLIENT_SERVICE_IMPL, IAPIClientService } from '../iapi-client.service';

@Injectable()
export class GeolocationService implements IGeolocationService {
    constructor(@Inject(API_CLIENT_SERVICE_IMPL) private _apiClient: IAPIClientService) {}

    public getCurrentPosition(): Observable<GeolocationPosition> {
        return new Observable((observer) => {
            if (window.navigator && window.navigator.geolocation) {
                window.navigator.geolocation.getCurrentPosition(
                    (position) => {
                        observer.next(position);
                        observer.complete();
                    },
                    (error) => observer.error(error)
                );
            } else {
                observer.error('Unsupported Browser');
            }
        });
    }

    public checkProviderShop(
        requestBody: CheckProviderShop
    ): Observable<HttpResponse<any>> {
        return this._apiClient.postWithResponse<any>(
            `geolocation/providershop`,
            requestBody
        );
    }

    public getProviderShopByLatlng(
        requestBody: CheckProviderShop
    ): Observable<HttpResponse<string>> {
        return this._apiClient.postWithResponse<string>(
            `geolocation/providershop-by-latlng`,
            requestBody
        );
    }
}
