import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { CheckProviderShop } from '../../dtos/check-provider-shop';

export const GEOLOCATION_SERVICE_IMPL = new InjectionToken<IGeolocationService>(
    'geolocationServiceImpl'
);

export interface IGeolocationService {
    getCurrentPosition(): Observable<GeolocationPosition>;
    checkProviderShop(requestBody: CheckProviderShop): Observable<HttpResponse<any>>;
    getProviderShopByLatlng(
        requestBody: CheckProviderShop
    ): Observable<HttpResponse<string>>;
}
